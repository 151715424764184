import React, { FC } from 'react';
import { SwiperSlide } from 'swiper/react';
import useIsBreakpoint from '../../../hooks/use-is-breakpoint/use-is-breakpoint';
import { BrandType } from '../../../models/brand-type';
import { BrandsCarouselType } from '../../../models/brands-carousel-type';
import BreakpointEnum from '../../../models/breakpoint-enum';
import ButtonSecondary from '../../atoms/button-secondary/button-secondary';
import BrandCard from '../../molecules/brand-card/brand-card';
import Carousel from '../../molecules/carousel/carousel';

const BrandsCarousel: FC<BrandsCarouselType> = ({
  callToAction,
  featuredBrands,
  heading,
}: BrandsCarouselType) => {
  const breakpoint = useIsBreakpoint();
  const breakpoints = {
    0: {
      centeredSlides: true,
      slidesPerGroup: 1,
      slidesPerView: 1.2,
    },
    641: {
      centeredSlides: false,
      slidesPerGroup: 2,
      slidesPerView: 2,
    },
    769: {
      slidesPerGroup: 4,
      slidesPerView: 4,
    },
    1025: {
      slidesPerGroup: 6,
      slidesPerView: 6,
    },
  };

  return (
    <div className="py-component-spacing-mobile lg:py-component-spacing">
      <Carousel
        breakpoints={breakpoints}
        heading={heading}
        numberOfSlides={featuredBrands.length}
        uniqueId="brands"
      >
        {featuredBrands.map((brand: BrandType) => (
          <SwiperSlide key={brand.name}>
            {({ isActive, isVisible }) => (
              <BrandCard
                {...brand}
                animate
                isActive={
                  breakpoint === BreakpointEnum.sm ? isActive : isVisible
                }
              />
            )}
          </SwiperSlide>
        ))}
      </Carousel>

      {callToAction && (
        <div className="flex justify-center mt-12">
          <ButtonSecondary {...callToAction} color="lemon-meringue" />
        </div>
      )}
    </div>
  );
};

export default BrandsCarousel;
