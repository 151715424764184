import { motion } from 'framer-motion';
import Link from 'next/link';
import React, { FC, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { BrandType } from '../../../models/brand-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import ProductImage from '../../atoms/product-image/product-image';

interface Props extends BrandType {
  readonly animate?: boolean;
  readonly className?: string;
  readonly isActive?: boolean;
}

const BrandCard: FC<Props> = ({
  animate,
  className,
  isActive,
  link,
  logo,
  logoAltText,
  name,
  numberOfProducts,
}: Props) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const variants = {
    isActive: { opacity: 1 },
    notActive: { opacity: 0 },
  };

  const transition = {
    damping: 18,
    delay: 0.1,
    duration: 0.5,
    type: 'spring',
  };

  return (
    <div
      className={
        `${className} ` +
        'bg-grey-50 flex flex-col h-full items-center px-6 py-4 lg:p-4 rounded-xl overflow-hidden'
      }
    >
      <motion.div
        animate={!animate || (animate && isActive) ? 'isActive' : 'notActive'}
        className="relative w-full p-4"
        transition={transition}
        variants={variants}
      >
        <Link href={link.url} passHref>
          <a
            href="replace"
            target={link.target}
            onClick={() => {
              pushToDataLayer(GtmEventNameEnum.GtmVideo, {
                brandName: name,
              });
            }}
          >
            <ProductImage alt={logoAltText} src={logo} />
          </a>
        </Link>
      </motion.div>

      <motion.div
        animate={!animate || (animate && isActive) ? 'isActive' : 'notActive'}
        className="flex flex-col w-full"
        transition={transition}
        variants={variants}
      >
        <span className="text-grey-600 text-sm">
          {numberOfProducts} product{numberOfProducts > 1 ? 's' : ''}
        </span>

        <Link href={link.url} passHref>
          <a
            className="flex items-center justify-between"
            href="replace"
            onClick={() => {
              pushToDataLayer(GtmEventNameEnum.BrandsCard, {
                brandName: name,
              });
            }}
            target={link.target}
          >
            <span className="font-bold">{link.name}</span>
            <IcomoonIcon color="french-blue" name="chevron-right" size="md" />
          </a>
        </Link>
      </motion.div>
    </div>
  );
};

BrandCard.defaultProps = {
  animate: false,
  className: '',
  isActive: false,
};

export default BrandCard;
